@import './colors.css';
@import './forms.scss';
@import './modals.scss';
@import './productCardRightImg.scss';

body {
  font-family: Rubik;
  color: var(--color-grey-text);
}

h1,
h2,
h3,
h4,
h5 {
  color: var(--color-black);
  font-weight: 200;
}
h1 {
  font-size: 2.5em;
}
h2 {
  font-size: 2em;
}
h3 {
  font-size: 1.8em;
}

button:disabled {
  opacity: 0.7;
}

button {
  font-family: Rubik;
}

.orangeText {
  color: var(--color-orange);
}
