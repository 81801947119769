:root {
  --color-black: #000000;
  --color-wine: #5c0f8b;
  --color-grey-text: #666;
  --color-grey-border: #b8b8b8;
  --color-grey-background: #eaeaea;
  --color-white: #fff;
  --color-orange: #ff9800;
  --color-red: #ff2828;
}
