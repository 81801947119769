.right-img {
  height: 300px;
  .card-area {
    display: flex;
    .card-offerer-logo {
      display: none;
    }
    .card-content {
      padding: 30px 5px 30px 30px;
      width: 50%;
      height: 240px;
      position: relative;
      .product-content {
        .description {
          -webkit-line-clamp: 8;
        }
        .price {
          padding: 0;
          position: absolute;
          bottom: 30px;
        }
      }
    }
    .card-img {
      height: 100%;
      min-height: 300px !important;
    }
  }
  &.item-modal {
    .card-area {
      width: 500px;
    }
  }
}
